@-webkit-keyframes header_startpage_headline {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, -100px);
            transform: translate(0, -100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes header_startpage_headline {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, -100px);
            transform: translate(0, -100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@-webkit-keyframes header_startpage_button {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes header_startpage_button {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@-webkit-keyframes header_startpage_photo {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, -30px);
            transform: translate(0, -30px);
  }
}
@keyframes header_startpage_photo {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, -30px);
            transform: translate(0, -30px);
  }
}
@-webkit-keyframes activate_search_agent_title {
  0% {
    opacity: 0;
    -webkit-transform: translate(-200px, 0);
            transform: translate(-200px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes activate_search_agent_title {
  0% {
    opacity: 0;
    -webkit-transform: translate(-200px, 0);
            transform: translate(-200px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@-webkit-keyframes activate_search_agent_icon {
  0% {
    opacity: 0.5;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes activate_search_agent_icon {
  0% {
    opacity: 0.5;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes activate_search_agent_text {
  0% {
    opacity: 0;
    -webkit-transform: translate(200px, 0);
            transform: translate(200px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes activate_search_agent_text {
  0% {
    opacity: 0;
    -webkit-transform: translate(200px, 0);
            transform: translate(200px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
body,
html {
  height: 100%;
  line-height: 21px;
  position: relative;
}
#ie10nomore {
  background: #fff;
  clear: both;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}
#ie10nomore .box {
  height: 250px;
  left: 50%;
  margin: -125px 0 0 -275px;
  position: relative;
  top: 50%;
  width: 550px;
}
#ie10nomore .browser {
  float: left;
  margin-top: 17px;
  width: 75px;
}
#ie10nomore h2 {
  color: #000;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 14px;
  padding: 0;
}
#ie10nomore p {
  color: #000;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 14px;
}
#ie10nomore a {
  color: #000;
  text-decoration: underline;
}
.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
* {
  box-sizing: border-box;
}
html {
  background: #f5f5f5;
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  color: #333;
  font-family: 'Roboto Condensed', sans-serif;
  font-smoothing: antialiased;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
body.no_scroll {
  overflow: hidden;
}
@media (min-width: 414px) {
  body {
    font-size: 1.5rem;
  }
}
@media (min-width: 640px) {
  body {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) {
  body {
    font-size: 1.6rem;
  }
}
@media (min-width: 1024px) {
  body {
    font-size: 1.7rem;
  }
}
@media (min-width: 1200px) {
  body {
    font-size: 1.8rem;
  }
}
h1 {
  color: #262626;
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 0.8em;
  letter-spacing: -0.04rem;
}
@media (min-width: 414px) {
  h1 {
    font-size: 2.2rem;
    letter-spacing: -0.044rem;
  }
}
@media (min-width: 640px) {
  h1 {
    font-size: 2.2rem;
    letter-spacing: -0.044rem;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 2.4rem;
    letter-spacing: -0.048rem;
  }
}
@media (min-width: 1024px) {
  h1 {
    font-size: 2.8rem;
    letter-spacing: -0.056rem;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 3.1rem;
    letter-spacing: -0.062rem;
  }
}
h2,
.h2 {
  color: #262626;
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.8em;
  padding: 0.8em 0 0;
  position: relative;
  letter-spacing: -0.01rem;
}
h2:after,
.h2:after {
  content: '';
  display: block;
  border-bottom: 1px solid #16364e;
  bottom: -0.4em;
  left: 0;
  position: absolute;
  width: 3.2em;
}
h2 .title_1,
.h2 .title_1 {
  display: block;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 0.4em;
}
@media (min-width: 414px) {
  h2,
  .h2 {
    font-size: 2.2rem;
    letter-spacing: -0.011rem;
  }
}
@media (min-width: 640px) {
  h2,
  .h2 {
    font-size: 2.2rem;
    letter-spacing: -0.011rem;
  }
}
@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 2.4rem;
    letter-spacing: -0.012rem;
  }
}
@media (min-width: 1024px) {
  h2,
  .h2 {
    font-size: 2.8rem;
    letter-spacing: -0.014rem;
  }
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2.6rem;
    letter-spacing: -0.013rem;
  }
}
h3,
.h3 {
  color: #262626;
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.8em;
  padding: 0.8em 0 0;
  position: relative;
  letter-spacing: -0.0085rem;
}
h3:after,
.h3:after {
  display: none;
}
@media (min-width: 414px) {
  h3,
  .h3 {
    font-size: 2.2rem;
    letter-spacing: -0.011rem;
  }
}
@media (min-width: 640px) {
  h3,
  .h3 {
    font-size: 2.2rem;
    letter-spacing: -0.011rem;
  }
}
@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 2.4rem;
    letter-spacing: -0.012rem;
  }
}
@media (min-width: 1024px) {
  h3,
  .h3 {
    font-size: 2.1rem;
    letter-spacing: -0.0105rem;
  }
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2.6rem;
    letter-spacing: -0.013rem;
  }
}
h4,
h5,
.h4 {
  color: #16364e;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  margin: 0 0 0.53333333em;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
}
h4:after,
h5:after,
.h4:after {
  display: none;
}
@media (min-width: 414px) {
  h4,
  h5,
  .h4 {
    font-size: 1.6rem;
    letter-spacing: 0.08rem;
  }
}
@media (min-width: 640px) {
  h4,
  h5,
  .h4 {
    font-size: 1.7rem;
    letter-spacing: 0.085rem;
  }
}
@media (min-width: 768px) {
  h4,
  h5,
  .h4 {
    font-size: 1.7rem;
    letter-spacing: 0.085rem;
  }
}
@media (min-width: 1024px) {
  h4,
  h5,
  .h4 {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }
}
@media (min-width: 1200px) {
  h4,
  h5,
  .h4 {
    font-size: 1.9rem;
    letter-spacing: 0.095rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
}
p {
  margin: 0 0 0.8em;
}
a {
  color: #333;
  transition: color 300ms;
  -webkit-tap-highlight-color: rgba(22, 54, 78, 0.1);
}
a[name]:focus {
  outline: 0;
}
a:active,
a.active {
  color: #333;
}
a:focus {
  color: #333;
  outline: 2px solid #808080;
  text-decoration: none;
}
a:hover {
  color: #333;
  text-decoration: none;
}
a:hover:focus {
  outline: 0;
}
a.touch_link {
  color: #333;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #16364e;
  display: inline-block;
  margin-bottom: 0.4em;
  padding: 0;
  text-decoration: underline;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #333;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 2px solid #808080;
}
a.touch_link:hover .title {
  color: #333;
  text-decoration: none;
}
.button {
  border-radius: 0;
  padding: 0 3.2em;
  outline: 0;
  transition: background 300ms, border 300ms, color 300ms, outline 300ms;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #333;
  color: #333;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: 0.085rem;
}
.button > span {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
}
.button .icon {
  fill: #333;
  height: 24px;
  width: 24px;
}
.button .text {
  font-weight: 600;
  padding: 0.53333333em 0;
}
.button .icon + .text {
  padding-left: 0.53333333em;
}
.button:focus {
  outline: 3px solid #a9a9a9;
  background: rgba(169, 169, 169, 0.9);
  border-color: #fff;
  color: #fff;
}
.button:focus .icon {
  fill: #fff;
}
.button:hover {
  outline: 3px solid #a9a9a9;
  background: rgba(169, 169, 169, 0.9);
  border-color: #fff;
  color: #fff;
}
.button:hover .icon {
  fill: #fff;
}
.button:active {
  background: rgba(194, 194, 194, 0.9);
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: 0.085rem;
}
.button:active > span {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
}
.button:active .icon {
  fill: #fff;
  height: 24px;
  width: 24px;
}
.button:active .text {
  font-weight: 600;
  padding: 0.53333333em 0;
}
.button:active .icon + .text {
  padding-left: 0.53333333em;
}
strong {
  font-weight: 600;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.8em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: '\201E';
}
blockquote:after {
  content: '\201C';
}
small {
  font-size: 85%;
}
sup {
  font-size: 70%;
  line-height: 1;
  position: relative;
  top: -50%;
}
sub {
  bottom: -50%;
  font-size: 70%;
  line-height: 1;
  position: relative;
}
.table {
  margin-bottom: 1.6em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
* + .table {
  margin-top: 1.6em;
}
table {
  border-bottom: 1px solid #c2c2c2;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 600;
  text-align: left;
}
td,
th {
  border-top: 1px solid #c2c2c2;
  padding: 0.53333333em;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f5f5f5;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.8em;
  padding: 0;
}
ol > li {
  padding-left: 1.6em;
  position: relative;
}
ol > li:before {
  color: #16364e;
  counter-increment: list;
  content: counter(list) '.';
  font-weight: 600;
  left: 0;
  text-align: right;
  line-height: 1.6;
  position: absolute;
  width: 1.2em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url('../images/list_style_image.svg');
  margin: 0 0 0.8em;
  padding-left: 1.2em;
}
ul ol,
ul ul {
  margin: 0;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.6em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.6em;
}
figure.floatright {
  float: right;
  margin-left: 1.6em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.4em;
  text-align: left;
}
.unveil {
  opacity: 0;
  visibility: hidden;
}
.unveil.show {
  opacity: 1;
  visibility: visible;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container object,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_container.preloader {
  background: #f5f5f5;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
svg a:focus {
  outline: 0;
}
.preloader {
  display: block;
  position: relative;
}
.preloader .icon_spin {
  -webkit-animation: preloader_rotate 900ms linear infinite;
          animation: preloader_rotate 900ms linear infinite;
  display: block;
  height: 48px;
  left: 50%;
  fill: #c2c2c2;
  margin: -24px 0 0 -24px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transition: 300ms;
  visibility: visible;
  width: 48px;
}
.preloader .icon_spin.hide {
  opacity: 0;
  visibility: hidden;
}
.no-cssanimations .icon_spin {
  display: none;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@media print {
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  .page_break_print {
    page-break-before: always;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    orphans: 3;
    page-break-after: avoid;
    widows: 3;
  }
  p {
    orphans: 3;
    text-align: justify;
    widows: 3;
  }
  .print_link:after {
    content: '(Link: ' attr(href) ')';
    color: black;
    display: block;
    font-size: 85%;
    margin: 0 0 0.8em;
    text-transform: none;
    word-wrap: break-word;
  }
  .table {
    overflow-y: inherit;
  }
  table {
    page-break-inside: avoid;
  }
  fieldset {
    border: 0;
    margin: 0;
  }
  .google_maps {
    page-break-before: always;
  }
  .map {
    display: inline-block;
    height: 400px;
    margin: 0 0 0.8em;
    padding: 1px 0 0;
    width: 100%;
  }
  body {
    display: block;
    overflow-x: visible;
  }
  #page {
    display: block;
  }
  main {
    padding-top: 1.6em;
  }
  .company_logo {
    display: block;
    max-width: 50%;
    width: 50%;
  }
  #baguetteBox-overlay {
    display: none !important;
  }
  #qr_code_print {
    border-top: 1px solid;
    margin: 1.6em 0 0;
    padding: 0.8em 0 0;
    page-break-inside: avoid;
  }
  #qr_code_print:after {
    clear: both;
  }
  #qr_code_print:after,
  #qr_code_print:before {
    content: '';
    display: table;
  }
  #qr_code_print img {
    float: left;
  }
  #qr_code_print p {
    margin-left: 160px;
  }
}
@media screen {
  #page {
    min-width: 320px;
    position: relative;
  }
  .container {
    margin: 0 auto;
    max-width: 1600px;
    min-width: 320px;
    padding: 0 15px;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    justify-content: space-between;
  }
  .row.start_xsmall {
    justify-content: flex-start;
  }
  .row.center_xsmall {
    justify-content: center;
  }
  .row.end_xsmall {
    justify-content: flex-end;
  }
  .row.top_xsmall {
    align-items: flex-start;
  }
  .row.middle_xsmall {
    align-items: center;
  }
  .row.bottom_xsmall {
    align-items: flex-end;
  }
  .row.baseline_xsmall {
    align-items: baseline;
  }
  .row.reverse_xsmall {
    flex-direction: row-reverse;
  }
  .row.reverse_xsmall .row {
    flex-direction: row;
  }
  .row.half_column_margin {
    margin-left: -3.75px;
    margin-right: -3.75px;
  }
  .row.half_column_margin > [class*=span] {
    padding-left: 3.75px;
    padding-right: 3.75px;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*=span] {
    padding-left: 0;
    padding-right: 0;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  [class*="span"].span_column {
    display: flex;
    flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  input,
  select,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
  }
  label {
    cursor: pointer;
  }
  ::-webkit-input-placeholder {
    color: #e8e8e8;
  }
  :-ms-input-placeholder {
    color: #e8e8e8;
  }
  ::-ms-input-placeholder {
    color: #e8e8e8;
  }
  ::placeholder {
    color: #e8e8e8;
  }
  .required_asterisk {
    color: #215276;
  }
  .required_description {
    font-size: 85%;
  }
  .error_warning {
    background: #275f8a;
    color: #fff;
    display: none;
    font-weight: 600;
    margin-right: 0.4em;
    padding: 0 0.4em;
    text-align: center;
  }
  .error_warning.show {
    display: inline-block;
  }
  .error_msg_button {
    display: none;
  }
  .label {
    display: block;
    margin-bottom: 0.4em;
  }
  .input,
  .textarea {
    background: #ffffff;
    border: 1px solid rgba(194, 194, 194, 0.9);
    border-radius: 0;
    box-shadow: none;
    color: #a9a9a9;
    margin: 0 0 0.8em;
    outline: 0;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  .input:focus,
  .textarea:focus {
    border-color: #333;
    background: rgba(51, 51, 51, 0.05);
    box-shadow: 0 0 3px #333 inset, 0 0 0 3px rgba(51, 51, 51, 0.2);
    color: #333;
  }
  .input:focus.error,
  .textarea:focus.error {
    box-shadow: 0 0 3px #275f8a inset, 0 0 0 3px rgba(39, 95, 138, 0.2);
  }
  .input.error,
  .textarea.error {
    border-color: #275f8a;
    background: rgba(39, 95, 138, 0.05);
    color: #16364e;
  }
  .input {
    height: 50px;
    padding: 0 0.53333333em;
    transition: border 300ms;
  }
  .input[type="number"] {
    -moz-appearance: textfield;
  }
  .input[type="number"]::-webkit-inner-spin-button,
  .input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .input[type="time"]::-webkit-inner-spin-button,
  .input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff inset;
  }
  .input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
  .textarea {
    line-height: 1.6;
    overflow: hidden;
    padding: 0.53333333em;
    transition: border 300ms, height 300ms;
    resize: none;
  }
  button {
    border-radius: 0;
    cursor: pointer;
    margin: 0 0 1.6em;
    outline: 0;
    overflow: hidden;
    padding: 0 1.6em;
    transition: background 300ms, border 300ms, color 300ms, outline 300ms;
    width: 100%;
    background: rgba(194, 194, 194, 0.9);
    border: 1px solid #fff;
    color: #333;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    letter-spacing: 0.085rem;
  }
  button > span {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 48px;
  }
  button .icon {
    fill: #333;
    height: 24px;
    width: 24px;
  }
  button .text {
    font-weight: 600;
    padding: 0.53333333em 0;
  }
  button .icon + .text {
    padding-left: 0.53333333em;
  }
  button:focus,
  button:hover {
    outline: 3px solid #a9a9a9;
    background: #a9a9a9;
    border-color: #fff;
    color: #fff;
  }
  button:focus .icon,
  button:hover .icon {
    fill: #fff;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .dialog_wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 1.6em 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 7100;
  }
  .dialog_wrapper:target {
    visibility: visible;
  }
  .dialog_wrapper:target .dialog_overlay {
    opacity: 1;
    visibility: visible;
  }
  .dialog_wrapper:target [role="dialog"] {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  .dialog_wrapper.show {
    visibility: visible;
  }
  .dialog_overlay {
    background: rgba(51, 51, 51, 0.5);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 300ms;
    visibility: hidden;
    width: 100%;
    z-index: 0;
  }
  .dialog_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] {
    background: #fff;
    border-radius: 0;
    box-shadow: rgba(51, 51, 51, 0.3) 0 0.1rem 0.6rem, rgba(51, 51, 51, 0.2) 0 0.1rem 0.4rem;
    margin: -10rem auto 0;
    max-height: 56rem;
    max-width: 640px;
    min-width: 305px;
    opacity: 0;
    outline: 0;
    padding: 1.6em 1.06666667em;
    position: relative;
    transition: all 300ms;
    visibility: hidden;
    width: calc(100% - 1.6em);
    z-index: 10;
  }
  [role="dialog"].show {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] .title {
    padding: 0 2em 0 0;
  }
  [role="dialog"] .description {
    padding: 0 0 0.8em 0;
  }
  [role="dialog"] .close {
    display: block;
    fill: #16364e;
    height: 50px;
    margin-left: auto;
    padding: 0.26666667em;
    position: absolute;
    right: 0.8em;
    top: 1.06666667em;
    transition: fill 300ms;
    width: 50px;
  }
  [role="dialog"] .close:focus,
  [role="dialog"] .close:hover {
    fill: #333;
  }
  .tooltip_wrapper {
    position: relative;
  }
  [role="tooltip"] {
    background: #4d4d4d;
    border: 1px solid #333;
    border-radius: 0;
    top: -8px;
    color: #fff;
    font-size: 85%;
    font-weight: 400;
    padding: 0.4em 0.8em;
    position: absolute;
    right: 0;
    z-index: 100;
  }
  [role="tooltip"]:after {
    border: 10px solid transparent;
    border-top-color: #4d4d4d;
    bottom: -20px;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 11px solid transparent;
    border-top-color: #333;
    bottom: -22px;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    margin-left: -11px;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  /*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.5.0
 * @url https://github.com/feimosi/baguetteBox.js
 */
  #baguetteBox-overlay {
    display: none;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    background-color: #222 !important;
    transition: opacity 0.5s ease;
  }
  #baguetteBox-overlay.visible {
    opacity: 1;
  }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    height: 100%;
    text-align: center;
  }
  #baguetteBox-overlay .full-image figure {
    display: inline;
    margin: 0;
    height: 100%;
  }
  #baguetteBox-overlay .full-image img {
    display: inline-block;
    width: auto;
    height: auto%;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  }
  #baguetteBox-overlay .full-image figcaption {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    line-height: 1.8;
    white-space: normal;
    color: #ccc;
    background-color: #000;
    font-family: sans-serif;
  }
  #baguetteBox-overlay .full-image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px;
  }
  #baguetteBox-slider {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    transition: left 0.4s ease, -webkit-transform 0.4s ease;
    transition: left 0.4s ease, transform 0.4s ease;
    transition: left 0.4s ease, transform 0.4s ease, -webkit-transform 0.4s ease;
  }
  #baguetteBox-slider.bounce-from-right {
    -webkit-animation: bounceFromRight 0.4s ease-out;
    animation: bounceFromRight 0.4s ease-out;
  }
  #baguetteBox-slider.bounce-from-left {
    -webkit-animation: bounceFromLeft 0.4s ease-out;
    animation: bounceFromLeft 0.4s ease-out;
  }
  @-webkit-keyframes bounceFromRight {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: -30px;
    }
    100% {
      margin-left: 0;
    }
  }
  @keyframes bounceFromRight {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: -30px;
    }
    100% {
      margin-left: 0;
    }
  }
  @-webkit-keyframes bounceFromLeft {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: 30px;
    }
    100% {
      margin-left: 0;
    }
  }
  @keyframes bounceFromLeft {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: 30px;
    }
    100% {
      margin-left: 0;
    }
  }
  .baguetteBox-button#next-button,
  .baguetteBox-button#previous-button {
    display: block !important;
    height: 60px;
    margin-top: -15px;
    top: 50%;
    width: 44px;
  }
  .baguetteBox-button {
    position: absolute;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 15%;
    background-color: #a9a9a9;
    color: #fff;
    font: 1.6em sans-serif;
    transition: background-color 0.4s ease;
  }
  .baguetteBox-button:hover,
  .baguetteBox-button:focus {
    background-color: #16364e;
    outline: 0;
  }
  .baguetteBox-button#next-button {
    right: 2%;
  }
  .baguetteBox-button#previous-button {
    left: 2%;
  }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    width: 30px;
    height: 30px;
  }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0;
  }
  /*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
  .spinner {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
  }
  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out;
  }
  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  @-webkit-keyframes bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    left: -99999px;
    overflow: hidden;
    top: -99999px;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 2000;
  }
  .bypass_blocks li {
    display: block;
    width: 320px;
  }
  .bypass_blocks a {
    background: #fff;
    color: #16364e;
    display: inline-block;
    font-size: 85%;
    height: 1px;
    left: -99999px;
    overflow: hidden;
    padding: 0.4em;
    position: absolute;
    text-decoration: none;
    top: -99999px;
    width: 1px;
  }
  .bypass_blocks a:focus {
    color: #16364e;
    height: auto;
    left: 0.8em;
    top: 0.8em;
    width: auto;
  }
  .property_search_wrapper,
  .property_specific_selection_wrapper {
    max-width: 1200px;
    position: relative;
    z-index: 10;
  }
  .property_search,
  .property_specific_selection {
    background: #fff;
  }
  .property_search .title,
  .property_specific_selection .title {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto 0.8em;
    padding: 0;
    position: relative;
  }
  .property_search .title:after,
  .property_specific_selection .title:after {
    content: '';
    border-bottom: 1px solid #a9a9a9;
    bottom: -0.32em;
    left: 50%;
    margin-left: -3.2em;
    position: absolute;
    width: 6.4em;
  }
  .property_search .title_1,
  .property_specific_selection .title_1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.6;
    text-transform: uppercase;
    padding-bottom: 0.8em;
  }
  .property_search .text_wrapper,
  .property_specific_selection .text_wrapper {
    font-size: 112.5%;
    padding: 1.6em 1.6em 0.53333333em;
    text-align: center;
  }
  .property_search_entry,
  .property_specific_selection_entry {
    background: #fff;
    border-top: 1px solid #fff;
    display: flex;
    flex-direction: column-reverse;
    max-width: 1200px;
    position: relative;
    z-index: 10;
  }
  .property_search_entry .title_1,
  .property_specific_selection_entry .title_1 {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .property_search_entry .title_1 .icon,
  .property_specific_selection_entry .title_1 .icon {
    fill: #16364e;
    height: 26px;
    width: 26px;
    margin-right: 0.32em;
  }
  .property_search_entry .description_wrapper,
  .property_specific_selection_entry .description_wrapper {
    padding: 0.8em 1.6em;
  }
  .property_search_entry .image,
  .property_specific_selection_entry .image {
    position: relative;
  }
  .property_search_entry .image .link,
  .property_specific_selection_entry .image .link {
    align-self: center;
    display: block;
    overflow: hidden;
    position: relative;
  }
  .property_search_entry .image .link img,
  .property_specific_selection_entry .image .link img {
    display: block;
    max-width: none;
    width: 100%;
  }
  .property_search_entry .image .discreet,
  .property_specific_selection_entry .image .discreet {
    align-items: center;
    background: #f5f5f5;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
  }
  .property_search_entry .image .discreet .icon,
  .property_specific_selection_entry .image .discreet .icon {
    display: block;
    fill: #c2c2c2;
    height: 65%;
    margin: 0.8em auto 0;
    padding: 1.6em;
    width: 70%;
  }
  .property_search_entry .property_details_search,
  .property_specific_selection_entry .property_details_search {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .property_search_entry .property_details_search li,
  .property_specific_selection_entry .property_details_search li {
    align-items: flex-start;
    -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
    77display: flex;
    display: inline-flex;
    padding: 0;
  }
  .property_search_entry .property_details_search strong,
  .property_specific_selection_entry .property_details_search strong {
    display: inline-block;
    min-width: 120px;
  }
  .property_search_entry .more_details,
  .property_specific_selection_entry .more_details {
    margin: 0.4em 0 1.6em;
    padding: 0 1.6em;
    text-transform: uppercase;
  }
  .property_search_entry .more_details a,
  .property_specific_selection_entry .more_details a {
    background: #16364e;
    color: #fff;
    display: inline-block;
    line-height: 50px;
    margin-bottom: 1px;
    padding: 0 1.6em;
    text-align: center;
    text-decoration: none;
    transition: background 300ms;
    width: 100%;
  }
  .property_search_entry .more_details a:focus,
  .property_specific_selection_entry .more_details a:focus {
    background: #333;
    outline: 0;
  }
  .property_search_entry .more_details a:hover,
  .property_specific_selection_entry .more_details a:hover {
    background: #333;
  }
  .property_search_entry .property_number,
  .property_specific_selection_entry .property_number {
    background: #a9a9a9;
    color: #fff;
    line-height: 50px;
    padding: 0 1.6em;
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    top: 0;
    z-index: 2000;
  }
  .property_contact_call_to_action {
    background: rgba(169, 169, 169, 0.05);
    max-width: 1200px;
    position: relative;
    z-index: 10;
  }
  .property_contact_call_to_action .contact_starts {
    background: #f5f5f5;
    padding: 1.6em;
    text-align: center;
  }
  .property_contact_call_to_action .contact_starts h2 {
    margin: 0 0 0.8em;
    padding: 0;
  }
  .property_contact_call_to_action .contact_starts h2:after {
    display: none;
  }
  .property_contact_call_to_action .contact_starts h2 strong {
    font-weight: 400;
  }
  .property_contact_call_to_action .contact_starts *:last-child {
    margin: 0;
  }
  .property_contact_call_to_action .contact_ends {
    padding: 1.6em;
  }
  .property_contact_call_to_action .contact_ends .title {
    padding: 0;
  }
  .property_contact_call_to_action .button {
    padding: 0;
    width: 100%;
    background: #dcdcdc;
    border: 1px solid #333;
    color: #333;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    letter-spacing: 0.085rem;
    margin-bottom: 0.8em;
  }
  .property_contact_call_to_action .button > span {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 48px;
  }
  .property_contact_call_to_action .button .icon {
    fill: #333;
    height: 24px;
    width: 24px;
  }
  .property_contact_call_to_action .button .text {
    font-weight: 600;
    padding: 0.53333333em 0;
  }
  .property_contact_call_to_action .button .icon + .text {
    padding-left: 0.53333333em;
  }
  .property_contact_call_to_action .button:focus,
  .property_contact_call_to_action .button:hover {
    outline: 3px solid #a9a9a9;
    background: #a9a9a9;
    border-color: #333;
    color: #fff;
  }
  .property_contact_call_to_action .button:focus .icon,
  .property_contact_call_to_action .button:hover .icon {
    fill: #fff;
  }
  .property_details {
    background: #fff;
  }
  .property_details .discreet {
    align-items: center;
    background: #f5f5f5;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    height: 175px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
  }
  .property_details .discreet .icon {
    display: block;
    fill: #c2c2c2;
    height: 65%;
    margin: 0.8em auto 0;
    padding: 1.6em;
    width: 70%;
  }
  .property_details .introduction_wrapper {
    order: 1;
    padding-top: 50px;
    position: relative;
  }
  .property_details .introduction_wrapper .introduction_starts {
    padding: 0.8em 1.6em;
  }
  .property_details .introduction_wrapper .details .text {
    padding: 0.8em 1.6em;
    background: rgba(169, 169, 169, 0.05);
  }
  .property_details .introduction_wrapper .details .text .title {
    color: #a9a9a9;
    font-size: 113.33333333%;
  }
  .property_details .introduction_wrapper .details .text .title:after {
    display: none;
  }
  .property_details .introduction_wrapper .details .text ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .property_details .introduction_wrapper .details .text li {
    display: flex;
    padding: 0;
  }
  .property_details .introduction_wrapper .details .text strong {
    display: inline-block;
    min-width: 130px;
  }
  .property_details .introduction_wrapper .details .links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    text-transform: uppercase;
  }
  .property_details .introduction_wrapper .details .links .print_expose,
  .property_details .introduction_wrapper .details .links .print_memo {
    background: rgba(169, 169, 169, 0.7);
    min-width: 50%;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    width: 50%;
  }
  .property_details .introduction_wrapper .details .links .recommend {
    background: rgba(169, 169, 169, 0.7);
    border-bottom: 1px solid #fff;
    min-width: 50%;
    width: 50%;
  }
  .property_details .introduction_wrapper .details .links .mark_object,
  .property_details .introduction_wrapper .details .links .print_memo {
    background: #16364e;
    border-bottom: 1px solid #fff;
    min-width: 100%;
  }
  .property_details .introduction_wrapper .details .links a {
    color: #fff;
    line-height: 50px;
    text-decoration: none;
    transition: background 300ms;
    position: relative;
  }
  .property_details .introduction_wrapper .details .links a:focus {
    background: #333;
    outline: 0;
  }
  .property_details .introduction_wrapper .details .links a:hover {
    background: #333;
  }
  .property_details .introduction_wrapper .property_number {
    background: #fff;
    display: flex;
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 55%;
    z-index: 200;
  }
  .property_details .introduction_wrapper .property_number .text {
    background: #a9a9a9;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 1.6em;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .new_property {
    background: #16364e;
    bottom: -50px;
    color: #fff;
    font-size: 144.44444444%;
    height: 100px;
    line-height: 1;
    padding: 5px 0;
    position: absolute;
    right: -50px;
    text-align: center;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    z-index: 100;
    width: 100px;
  }
  .successfully_mediated_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
  }
  .successfully_mediated {
    background: #c2c2c2;
    color: #fff;
    display: inline-block;
    font-size: 125%;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 0.8em;
    text-transform: uppercase;
    width: 100%;
  }
  .discreet_sale {
    background: #c2c2c2;
    color: #fff;
    display: inline-block;
    font-size: 125%;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    margin-top: auto;
    padding: 0 0.4em;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }
  .property_details_slider {
    order: 0;
    overflow: hidden;
    position: relative;
    height: 0;
    padding-bottom: 50%;
  }
  .property_details_slider .wrapper {
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .property_details_slider .slide {
    background-size: cover;
    background-position: 50%;
    margin: 0;
    position: relative;
    flex: 1 0 100%;
  }
  .property_details_slider .control ul {
    display: flex;
    flex-direction: row;
  }
  .property_details_slider .control li {
    background: #a9a9a9;
    border: 1px solid #fff;
    cursor: pointer;
    display: block;
    fill: #fff;
    height: 40px;
    margin-top: -20px;
    padding: 10px 12px;
    position: absolute;
    top: 50%;
    transition: fill 300ms;
    width: 40px;
    z-index: 100;
  }
  .property_details_slider .control li:focus {
    background: #16364e;
    outline: 0;
  }
  .property_details_slider .control li:hover {
    background: #16364e;
  }
  .property_details_slider .control li.prev {
    left: 0.8em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .property_details_slider .control li.next {
    right: 0.8em;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .property_details_long_description {
    order: 2;
    padding: 1.6em 1.6em 0.8em;
  }
  .property_details_images_no_image_enlargement,
  .property_details_images {
    display: flex;
    flex-wrap: wrap;
    order: 3;
    padding: 1px;
  }
  .property_details_images_no_image_enlargement .image_container,
  .property_details_images .image_container {
    border: 1px solid #fff;
    display: block;
    flex-grow: 1;
    height: 140px;
    max-width: 50%;
    min-width: 50%;
    width: 50%;
  }
  .property_details_images_no_image_enlargement .image_container .entry,
  .property_details_images .image_container .entry {
    background-position: 50%;
    background-size: cover;
    border: 2px solid #fff;
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .property_details_images_no_image_enlargement .image_container .entry .description_text,
  .property_details_images .image_container .entry .description_text {
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    padding: 1rem;
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: -70px;
    transition: bottom 300ms;
  }
  .property_details_images_no_image_enlargement .image_container .entry:hover .description_text,
  .property_details_images .image_container .entry:hover .description_text,
  .property_details_images_no_image_enlargement .image_container .entry:focus .description_text,
  .property_details_images .image_container .entry:focus .description_text {
    bottom: 0;
    transition: bottom 300ms;
  }
  .property_details_images_no_image_enlargement .image_container a.entry:focus,
  .property_details_images .image_container a.entry:focus,
  .property_details_images_no_image_enlargement .image_container a.entry:hover,
  .property_details_images .image_container a.entry:hover {
    outline: 2px solid #a9a9a9;
    z-index: 10;
  }
  .property_details_contact {
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100%;
    order: 4;
    padding: 0.8em 1.6em;
    position: relative;
  }
  .property_details_contact .text_wrapper {
    position: relative;
    z-index: 10;
  }
  .property_details_contact a[href^="tel:"] {
    color: #16364e;
  }
  .social_media_links {
    display: block;
    position: absolute;
    right: 1.6em;
    top: 0.8em;
  }
  .social_media_links a {
    background: #16364e;
    border: 1px solid #fff;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 40px;
    padding: 0.32em;
    width: 40px;
  }
  .social_media_share ul {
    display: flex;
    flex-wrap: wrap;
    margin: 1.6em 0 0.8em 0;
    padding: 0;
  }
  .social_media_share li {
    flex-basis: 100%;
    flex-shrink: 0;
    list-style: none;
    padding: 0;
    margin: 0.4em 0;
  }
  .social_media_share a {
    background: #f5f5f5;
    color: #333;
    cursor: pointer;
    display: block;
    padding: 0 0.8em;
    text-decoration: none;
    transition: background 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .social_media_share a > span {
    align-items: center;
    display: flex;
    height: 50px;
  }
  .social_media_share a .icon {
    fill: #333;
    height: 24px;
    width: 24px;
  }
  .social_media_share a .text {
    font-weight: 600;
    padding: 0.53333333em 0 0.53333333em 0.8em;
  }
  .social_media_share a:hover {
    color: #fff;
  }
  .social_media_share a:hover .icon {
    fill: #fff;
  }
  .social_media_share a.facebook:hover {
    background: #3b5998;
  }
  .social_media_share a.twitter:hover {
    background: #55acee;
  }
  .social_media_share a.print:hover {
    background: #16364e;
  }
  .edit_in_cms {
    display: none;
  }
  .dropdown_navigation {
    display: none;
  }
  [data-offcanvas-menu-visible] {
    display: flex;
    min-height: 100vh;
    min-width: 320px;
    position: relative;
    z-index: 5000;
    width: 100%;
    overflow: hidden;
  }
  [data-offcanvas-menu-visible][data-offcanvas-menu-position="left"] {
    flex-direction: row-reverse;
  }
  [data-offcanvas-menu-visible] .page_wrapper {
    box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.35), 0 0 2px 0 rgba(51, 51, 51, 0.25);
    min-height: 100vh;
    min-width: 100%;
    position: relative;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    z-index: 5000;
  }
  [data-offcanvas-menu-visible] .mobile_menu {
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  [data-offcanvas-menu-visible="true"][data-offcanvas-menu-position="left"] .page_wrapper {
    -webkit-transform: translate(265px, 0);
            transform: translate(265px, 0);
  }
  [data-offcanvas-menu-visible="true"][data-offcanvas-menu-position="left"] .mobile_menu {
    -webkit-transform: translate(265px, 0);
            transform: translate(265px, 0);
  }
  .show_mobile_menu {
    align-items: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 106.66666667%;
    height: 50px;
    left: -0.8em;
    margin-left: 1.6em;
    padding: 0 0.8em;
    position: relative;
    text-decoration: none;
  }
  .show_mobile_menu:hover {
    color: #fff;
    text-decoration: underline;
  }
  .show_mobile_menu:focus {
    color: #fff;
    text-decoration: underline;
    outline: 0;
  }
  .show_mobile_menu[aria-expanded="true"] .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: -webkit-transform 350ms;
    transition: transform 350ms;
    transition: transform 350ms, -webkit-transform 350ms;
  }
  .show_mobile_menu[aria-expanded="true"] .line {
    background: #fff;
  }
  .show_mobile_menu[aria-expanded="true"] .line_1 {
    top: 13px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .show_mobile_menu[aria-expanded="true"] .line_2 {
    opacity: 0;
    transition: all 300ms;
    visibility: hidden;
  }
  .show_mobile_menu[aria-expanded="true"] .line_3 {
    bottom: 13px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .show_mobile_menu .icon {
    height: 30px;
    margin-right: 0.8em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 26px;
  }
  .show_mobile_menu .line {
    background: #fff;
    display: block;
    height: 4px;
    transition: background 300ms;
    position: absolute;
    width: 100%;
  }
  .show_mobile_menu .line_1 {
    opacity: 1;
    top: 3px;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .show_mobile_menu .line_2 {
    opacity: 1;
    top: 13px;
    transition: all 300ms;
    visibility: visible;
  }
  .show_mobile_menu .line_3 {
    bottom: 3px;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .mobile_menu {
    background: #a9a9a9;
    display: none;
    flex-basis: 265px;
    flex-shrink: 0;
    padding: 1.6em 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .mobile_menu[aria-hidden="false"] {
    display: block;
  }
  .mobile_menu > ul > li {
    margin: 0.4em 0;
    border-bottom: 1px solid #a3a3a3;
    border-top: 1px solid #a3a3a3;
  }
  .mobile_menu a {
    color: #fff;
    display: block;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }
  .mobile_menu a:focus,
  .mobile_menu a:hover {
    text-decoration: underline;
  }
  .mobile_menu .root_menuitem {
    align-items: center;
    background: rgba(255, 255, 255, 0.06);
    display: flex;
    padding: 0.8em 1.6em;
    text-transform: uppercase;
  }
  .mobile_menu .root_menuitem:focus {
    outline: 0;
  }
  .mobile_menu .root_menuitem.active {
    background: rgba(255, 255, 255, 0.25);
    color: #333;
  }
  .mobile_menu .root_menuitem.active .icon {
    fill: #333;
  }
  .mobile_menu .root_menuitem .icon {
    display: block;
    fill: #fff;
    height: 15px;
    line-height: 10px;
    margin-left: 10px;
    width: 10px;
  }
  .mobile_menu .first_menuitem {
    align-items: center;
    display: flex;
    font-family: 'Libre Baskerville', sans-serif;
    font-size: 80%;
    padding: 0.8em 1.6em;
    text-transform: uppercase;
  }
  .mobile_menu .first_menuitem.active {
    color: #333;
  }
  .mobile_menu .first_menuitem.active .icon {
    fill: #333;
  }
  .mobile_menu .first_menuitem .icon_wrapper {
    height: 30px;
    margin-right: 0.8em;
    width: 30px;
  }
  .mobile_menu .first_menuitem .icon {
    display: block;
    fill: #fff;
    height: 30px;
    position: relative;
  }
  .mobile_menu .first_menu li:nth-child(1) .icon {
    left: 2px;
    width: 25px;
  }
  .mobile_menu .first_menu li:nth-child(2) .icon {
    width: 28px;
  }
  .mobile_menu .first_menu li:nth-child(3) .icon {
    width: 25px;
  }
  .mobile_menu .first_menu li:nth-child(4) .icon {
    width: 30px;
  }
  .mobile_menu .first_menu li:nth-child(5) .icon {
    left: 1px;
    width: 25px;
  }
  .mobile_menu .first_menu li:last-child {
    padding-bottom: 0.8em;
  }
  .mobile_menu ul[aria-hidden] {
    background: rgba(255, 255, 255, 0.06);
    overflow: hidden;
    transition: all 600ms;
    visibility: visible;
  }
  .mobile_menu ul[aria-hidden="true"] {
    max-height: 0;
    visibility: hidden;
  }
  .notification_center_wrapper {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .notification_center_wrapper .container {
    position: relative;
  }
  .notification_center {
    background: #fff;
    box-shadow: rgba(51, 51, 51, 0.3) 0 1px 6px, rgba(51, 51, 51, 0.2) 0 1px 4px;
    bottom: 0.8em;
    opacity: 0;
    position: absolute;
    right: 0.8em;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
    visibility: hidden;
    width: 298px;
  }
  .notification_center.visible {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .notification_center.reduced .content {
    display: none;
  }
  .notification_center .header {
    align-items: center;
    background: #333;
    color: #fff;
    display: flex;
    padding: 0.26666667em 0.4em 0.26666667em 0.8em;
  }
  .notification_center .header .title_wrapper {
    color: #fff;
    font-size: 100%;
    margin: 0 auto 0 0;
    padding: 0;
  }
  .notification_center .header a {
    display: inline-flex;
    height: 40px;
    width: 40px;
  }
  .notification_center .header a:focus {
    outline: 0;
  }
  .notification_center .header a.toggle .icon {
    padding: 0 7px;
  }
  .notification_center .header a.toggle.reduced .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .notification_center .header a .icon {
    border: 1px solid #fff;
    display: inline-block;
    fill: #fff;
    height: 30px;
    margin: auto;
    width: 30px;
  }
  .notification_center .entries {
    background: #fff;
    max-height: 185px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 0 0 0 0;
  }
  .notification_center .entry {
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
    display: flex;
    padding: 0.4em 0.4em 0.4em 0.8em;
  }
  .notification_center .entry:last-child {
    border: 0;
  }
  .notification_center .entry .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    width: 100%;
  }
  .notification_center .entry .title,
  .notification_center .entry .text {
    font-size: 85%;
    line-height: 1.2;
    margin: 0;
  }
  .notification_center .entry .title_wrapper {
    flex: 0 1 100%;
    max-width: 235px;
    padding-right: 0.8em;
  }
  .notification_center .entry .icon_wrapper {
    flex: 1 0 auto;
    display: flex;
    margin-left: auto;
    max-width: 32px;
    width: 32px;
  }
  .notification_center .entry .icon_wrapper .remove {
    background: #f5f5f5;
    border-radius: 50%;
    display: inline-block;
    height: 32px;
    width: 32px;
  }
  .notification_center .entry .icon_wrapper .remove:hover .icon svg {
    fill: #16364e;
  }
  .notification_center .entry .icon_wrapper .remove:focus {
    background: #16364e;
    outline: 0;
  }
  .notification_center .entry .icon_wrapper .remove:focus .icon svg {
    fill: #fff;
  }
  .notification_center .entry .icon_wrapper .icon {
    display: inline-block;
    height: 32px;
    padding: 0 8px;
    transition: fill 300ms;
    width: 32px;
  }
  .notification_center .entry .icon_wrapper .icon svg {
    fill: #333;
  }
  .notification_center .footer {
    display: flex;
  }
  .notification_center .footer .button {
    background: #16364e;
    color: #fff;
    display: inline-block;
    line-height: 50px;
    margin-left: auto;
    padding: 0 1.6em;
    text-align: center;
    text-decoration: none;
    transition: background 300ms;
  }
  .notification_center .footer .button:focus {
    background: #333;
    outline: 0;
  }
  .notification_center .footer .button:hover {
    background: #333;
  }
  .image_gallery_overview {
    background: #fff;
    padding-bottom: 2px;
  }
  .image_text {
    margin: 0;
    max-width: 1200px;
  }
  .teaser_overview {
    clear: both;
    background: #f5f5f5;
    max-width: 1200px;
    position: relative;
    z-index: 10;
    padding: 2.4em 3.2em;
  }
  .teaser_overview .teaser .left {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    flex-basis: 25%;
    min-width: 175px;
    padding: 1.2em;
    position: relative;
    text-align: center;
    width: 25%;
  }
  .teaser_overview .teaser .left:before {
    background: rgba(245, 245, 245, 0.7);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  .teaser_overview .teaser .left .icon_title {
    margin: 0;
    padding: 0;
  }
  .teaser_overview .teaser .right {
    padding: 0;
  }
  .teaser_overview .teaser .right h3 {
    text-decoration: underline;
  }
  .leistungen_overview .award img {
    margin: 0 0 0 auto;
    display: block;
    max-width: 150px;
    padding-bottom: 0.8em;
    padding-left: 0.8em;
    float: right;
  }
  .leistungen_overview .kundenstimmen {
    clear: both;
  }
  .leistungen_overview .kundenstimmen .entry_wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .leistungen_overview .kundenstimmen .entry_wrapper h3 a {
    font-size: 1.5rem;
  }
  #page {
    min-width: 320px;
    max-width: 1600px;
    margin: 0 auto;
  }
  .disclaimer {
    background: #f5f5f5;
    border-radius: 0;
    color: #333;
    font-size: 85%;
    margin-bottom: 0.8em;
    min-width: 100%;
    padding: 1.06666667em 1.6em;
  }
  header {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 300;
  }
  .header_wrapper {
    align-items: center;
    background: #16364e;
    display: flex;
    flex-wrap: wrap;
  }
  .company_logo {
    display: block;
    padding: 0.8em 1.6em 0;
    position: relative;
    width: 272px;
    z-index: 100;
  }
  .company_logo:focus {
    outline: 1px solid #a9a9a9;
  }
  .company_logo:hover:focus {
    outline: 0;
  }
  .company_logo_1 {
    display: block;
  }
  .select_language {
    margin-left: auto;
    margin-right: 1.6em;
  }
  .select_language ul {
    display: flex;
  }
  .select_language a {
    color: #fff;
    display: block;
    font-size: 106.66666667%;
    font-weight: 300;
    line-height: 65px;
    padding: 0 0 0 0.53333333em;
    text-decoration: none;
  }
  .select_language a:hover {
    text-decoration: underline;
  }
  .mobile_menu_logo_wrapper {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    min-width: 100%;
  }
  main {
    display: block;
    position: relative;
  }
  main.no_image {
    background-color: #fff;
  }
  .error_page main {
    padding: 1.6em 1.6em 3.2em;
  }
  .fixed_background_wrapper {
    display: none;
  }
  .fixed_background {
    background-size: cover;
    background-position: 50%;
    height: 100vh;
    margin: 0 auto;
    max-width: 1600px;
  }
  .header_startpage {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    position: relative;
    overflow: hidden;
  }
  .header_startpage h1 {
    -webkit-animation: header_startpage_headline 600ms forwards;
            animation: header_startpage_headline 600ms forwards;
    color: #fff;
    font-size: 186.66666667%;
    line-height: 1.45;
    letter-spacing: -20;
    max-width: 1400px;
    margin: 0 auto 1.6em;
    opacity: 0;
    padding: 0 3.2em 0.53333333em;
    position: relative;
    text-align: center;
    text-shadow: 0 1px 2px rgba(51, 51, 51, 0.75), 0 -1px 1px rgba(51, 51, 51, 0.25);
    width: 100%;
  }
  .header_startpage h1:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    bottom: 0;
    content: '';
    left: 50%;
    margin-left: -80px;
    position: absolute;
    width: 180px;
  }
  .header_startpage p {
    -webkit-animation: header_startpage_button 600ms forwards;
            animation: header_startpage_button 600ms forwards;
    opacity: 0;
  }
  .header_startpage .header_photo {
    -webkit-animation: header_startpage_photo 900ms forwards;
            animation: header_startpage_photo 900ms forwards;
    background-position: 50%;
    background-size: cover;
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .header_startpage .text_wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    max-width: 1400px;
    width: 100%;
  }
  .description_karin_gornik .photo {
    padding-top: 2px;
  }
  .description_karin_gornik .photo img {
    display: block;
    margin-left: auto;
  }
  .description_karin_gornik .text_wrapper {
    padding: 0.8em 1.6em 1.6em;
    position: relative;
    z-index: 10;
  }
  .description_karin_gornik .title_1 {
    display: block;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 0.8em;
  }
  .description_karin_gornik .sign,
  .about_gornik .contact_ends .sign {
    align-items: flex-end;
    background-image: url('../images/unterschrift_karin_gornik@2x.png');
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    height: 75px;
    margin-top: 10px;
    padding-top: 85px;
    width: 272px;
  }
  .about_gornik {
    background: #f5f5f5;
    max-width: 1200px;
    position: relative;
    z-index: 10;
  }
  .about_gornik .contact_starts {
    background: #f5f5f5;
    padding: 1.6em;
  }
  .about_gornik .contact_starts h1 {
    display: block;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  .about_gornik .contact_starts h1:after {
    display: none;
  }
  .about_gornik .contact_starts h1 strong {
    font-weight: 400;
  }
  .about_gornik .contact_starts *:last-child {
    margin: 0;
  }
  .about_gornik .contact_ends {
    padding: 1.6em;
  }
  .activate_search_agent {
    align-items: center;
    background: #16364e;
    padding: 1.06666667em 1.6em 1.6em;
    position: relative;
    z-index: 10;
  }
  .activate_search_agent.visible .title {
    -webkit-animation: activate_search_agent_title 900ms forwards;
            animation: activate_search_agent_title 900ms forwards;
  }
  .activate_search_agent.visible .icon {
    -webkit-animation: activate_search_agent_icon 1200ms linear;
            animation: activate_search_agent_icon 1200ms linear;
  }
  .activate_search_agent.visible .text_wrapper {
    -webkit-animation: activate_search_agent_text 900ms forwards;
            animation: activate_search_agent_text 900ms forwards;
  }
  .activate_search_agent .title {
    color: #fff;
    flex-basis: 450px;
    line-height: 1.1;
    opacity: 0;
    margin: 0 auto 0.8em;
    padding: 0 1.6em;
    text-align: center;
  }
  .activate_search_agent .title:after {
    display: none;
  }
  .activate_search_agent .title span {
    font-size: 58.82352941%;
  }
  .activate_search_agent .icon {
    display: none;
  }
  .activate_search_agent .text_wrapper {
    margin: 0;
    opacity: 0;
    text-align: center;
  }
  .activate_search_agent .text_wrapper .button {
    text-align: center;
    padding: 0 0.8em;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #fff;
    color: #16364e;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: transparent;
    letter-spacing: 0.085rem;
  }
  .activate_search_agent .text_wrapper .button > span {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 48px;
  }
  .activate_search_agent .text_wrapper .button .icon {
    fill: #16364e;
    height: 24px;
    width: 24px;
  }
  .activate_search_agent .text_wrapper .button .text {
    font-weight: 600;
    padding: 0.53333333em 0;
  }
  .activate_search_agent .text_wrapper .button .icon + .text {
    padding-left: 0.53333333em;
  }
  .activate_search_agent .text_wrapper .button:focus,
  .activate_search_agent .text_wrapper .button:hover {
    background: #a9a9a9;
    border-color: #fff;
    color: #fff;
  }
  .activate_search_agent .text_wrapper .button:focus .icon,
  .activate_search_agent .text_wrapper .button:hover .icon {
    fill: #fff;
  }
  .dialog_search_agent .input_field {
    width: 100%;
  }
  .dialog_search_agent .button_wrapper button {
    margin-left: 0;
  }
  .search_agent_content {
    background: #fff;
    max-width: 1200px;
    padding: 1.6em 1.6em 0.53333333em;
    position: relative;
    z-index: 10;
  }
  .breadcrumbs {
    display: none;
  }
  .legal_notice_address,
  .address_atikon,
  .legal_notice_text,
  .default_text {
    background: #fff;
    max-width: 1200px;
    padding: 1.6em 1.6em 0.53333333em;
    position: relative;
    z-index: 10;
  }
  .legal_notice_address .contact_wrapper,
  .address_atikon .contact_wrapper,
  .legal_notice_text .contact_wrapper,
  .default_text .contact_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .legal_notice_address .contact_wrapper p,
  .address_atikon .contact_wrapper p,
  .legal_notice_text .contact_wrapper p,
  .default_text .contact_wrapper p {
    margin-right: 2.4em;
  }
  .legal_notice_address .contact,
  .address_atikon .contact,
  .legal_notice_text .contact,
  .default_text .contact {
    display: flex;
  }
  .legal_notice_address .contact strong,
  .address_atikon .contact strong,
  .legal_notice_text .contact strong,
  .default_text .contact strong {
    min-width: 70px;
  }
  .default_text {
    margin-bottom: 1.6em !important;
  }
  .default_text h1 {
    padding-top: 0.53333333em;
  }
  .legal_notice_text {
    padding: 0 1.6em 1px;
  }
  .address_atikon {
    padding-top: 0;
    padding-bottom: 0.8em;
  }
  .address_atikon figure {
    margin-bottom: 0.8em;
  }
  footer {
    position: relative;
  }
  footer .links_overview {
    background-color: #fff;
  }
  footer .links_overview ul li {
    list-style: none;
  }
  #author {
    display: block;
    bottom: 1.6em;
    fill: #a9a9a9;
    height: 9px;
    line-height: 9px;
    position: absolute;
    right: 1.6em;
    width: 75px;
  }
  .footer_address {
    background: #fff;
    max-width: 1600px;
    padding: 0 1.6em 3.2em;
  }
  .footer_address .footer_links {
    padding: 0 1.6em;
  }
  .footer_address .logos {
    padding: 0.8em 0.8em 1.6em;
    text-align: center;
  }
  .footer_address .address {
    padding: 0.8em 0.8em 1.6em;
    text-align: center;
  }
  .footer_address .address {
    border-bottom: 1px solid #a9a9a9;
  }
  .footer_address .address .title {
    color: #333;
  }
  .footer_address .address .phone_and_email {
    font-size: 120%;
    font-weight: 300;
    line-height: 1.44;
    margin-bottom: 0.8em;
  }
  .footer_address .address .phone_and_email a {
    color: #16364e;
  }
  .footer_address .logos img {
    display: block;
    margin: 1.6em auto;
  }
  .footer_address .logos .logo_wrapper_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer_address .logos .logo_wrapper_4 {
    display: flex;
    justify-content: space-around;
  }
  .footer_address .logos .logo_wrapper_3 img {
    margin: 0 auto 0.8em;
  }
  .contact_button_footer {
    background: #16364e;
    color: #fff;
    display: inline-block;
    line-height: 50px;
    margin: 0 auto 1.06666667em;
    padding: 0 3.2em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 300ms;
  }
  .contact_button_footer:focus {
    background: #333;
    color: #fff;
    outline: 0;
  }
  .contact_button_footer:hover {
    background: #333;
    color: #fff;
  }
  .news_overview .image_text {
    background: none;
  }
  .news_overview .image_text.property_search {
    padding: 0;
  }
  .news_overview .image_text .header {
    background: #fff;
    text-align: center;
    margin: 0;
    padding: 2.4em 2.4em 1.6em;
  }
  .news_overview .title {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto 0.8em;
    padding: 0;
    position: relative;
  }
  .news_overview .title::after {
    content: '';
    border-bottom: 1px solid #a9a9a9;
    bottom: -0.32em;
    left: 50%;
    margin-left: -3.2em;
    position: absolute;
    width: 6.4em;
  }
  .news_overview .entry {
    background: #fff;
    border-bottom: 1px solid #a9a9a9;
    margin: 0;
    padding: 2.4em 2.4em 1.6em;
  }
  .news_overview .introduction_text {
    margin-top: 3rem;
  }
  .news_overview .text {
    font-size: 1.8rem;
    text-align: left;
    vertical-align: top;
    margin-top: 2rem;
  }
  .news_overview .text .button_wrapper {
    margin-top: 2rem;
  }
  .news_overview .text .button_wrapper .button {
    background: #16364e;
    border: 0;
    color: #fff;
    display: inline-block;
    line-height: 50px;
    margin-bottom: 1px;
    padding: 0 1.6em;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s;
  }
  .news_overview .text .button_wrapper .button:hover {
    background: #333;
    border: 0;
    outline: 0;
    line-height: 50px;
    padding: 0 1.6em;
  }
  .news_overview .text h2 {
    padding-top: 0;
  }
  .news_overview_detail .introduction_text {
    margin-top: 3rem;
  }
  .news_entry {
    text-align: left;
    font-size: 100%;
  }
  .news_entry time,
  .news_entry p {
    font-size: 88%;
  }
  .news_entry .property_details_images_no_image_enlargement,
  .news_entry .property_details_images {
    display: flex;
    flex-wrap: wrap;
    order: 3;
    padding: 1px;
  }
  .news_entry .property_details_images_no_image_enlargement .image_container,
  .news_entry .property_details_images .image_container {
    border: 1px solid #fff;
    display: block;
    flex-grow: 1;
    height: 140px;
    max-width: 50%;
    min-width: 50%;
    width: 50%;
  }
  .news_entry .property_details_images_no_image_enlargement .image_container .entry,
  .news_entry .property_details_images .image_container .entry {
    background-position: 50%;
    background-size: cover;
    border: 2px solid #fff;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .news_entry .property_details_images_no_image_enlargement .image_container a.entry:focus,
  .news_entry .property_details_images .image_container a.entry:focus,
  .news_entry .property_details_images_no_image_enlargement .image_container a.entry:hover,
  .news_entry .property_details_images .image_container a.entry:hover {
    outline: 2px solid #a9a9a9;
    z-index: 10;
  }
  #bellevue_logo_wrapper {
    position: absolute;
    right: 12px;
    top: 0;
    width: 50px;
    z-index: 9999;
  }
  .menu_aktuelle_objekte {
    z-index: 99999;
  }
  #property_details_images .video_wrapper {
    height: 100%;
  }
  #property_details_images .video_container {
    background: #000;
    height: 208px !important;
  }
}
@media screen and (max-width: 413px) {
  .hideme_xsmall {
    display: none;
  }
}
@media screen and (min-width: 414px) and (max-width: 639px) {
  .hideme_small {
    display: none;
  }
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  .hideme_medium {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hideme_large {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .hideme_xlarge {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .hideme_xxlarge {
    display: none;
  }
}
@media screen and (min-width: 414px) {
  .container {
    padding: 0 20px;
  }
}
@media screen and (min-width: 640px) {
  .container {
    padding: 0 30px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    padding: 0 40px;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    padding: 0 50px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 60px;
  }
}
@media screen and (min-width: 414px) {
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    justify-content: space-between;
  }
  .row.start_small {
    justify-content: flex-start;
  }
  .row.center_small {
    justify-content: center;
  }
  .row.end_small {
    justify-content: flex-end;
  }
  .row.top_small {
    align-items: flex-start;
  }
  .row.middle_small {
    align-items: center;
  }
  .row.bottom_small {
    align-items: flex-end;
  }
  .row.baseline_small {
    align-items: baseline;
  }
  .row.reverse_small {
    flex-direction: row-reverse;
  }
  .row.reverse_small .row {
    flex-direction: row;
  }
  .row.half_column_margin {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row.half_column_margin > [class*=span] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (min-width: 640px) {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    justify-content: space-between;
  }
  .row.start_medium {
    justify-content: flex-start;
  }
  .row.center_medium {
    justify-content: center;
  }
  .row.end_medium {
    justify-content: flex-end;
  }
  .row.top_medium {
    align-items: flex-start;
  }
  .row.middle_medium {
    align-items: center;
  }
  .row.bottom_medium {
    align-items: flex-end;
  }
  .row.baseline_medium {
    align-items: baseline;
  }
  .row.reverse_medium {
    flex-direction: row-reverse;
  }
  .row.reverse_medium .row {
    flex-direction: row;
  }
  .row.half_column_margin {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .row.half_column_margin > [class*=span] {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
@media screen and (min-width: 768px) {
  .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    justify-content: space-between;
  }
  .row.start_large {
    justify-content: flex-start;
  }
  .row.center_large {
    justify-content: center;
  }
  .row.end_large {
    justify-content: flex-end;
  }
  .row.top_large {
    align-items: flex-start;
  }
  .row.middle_large {
    align-items: center;
  }
  .row.bottom_large {
    align-items: flex-end;
  }
  .row.baseline_large {
    align-items: baseline;
  }
  .row.reverse_large {
    flex-direction: row-reverse;
  }
  .row.reverse_large .row {
    flex-direction: row;
  }
  .row.half_column_margin {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row.half_column_margin > [class*=span] {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .row {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    justify-content: space-between;
  }
  .row.start_xlarge {
    justify-content: flex-start;
  }
  .row.center_xlarge {
    justify-content: center;
  }
  .row.end_xlarge {
    justify-content: flex-end;
  }
  .row.top_xlarge {
    align-items: flex-start;
  }
  .row.middle_xlarge {
    align-items: center;
  }
  .row.bottom_xlarge {
    align-items: flex-end;
  }
  .row.baseline_xlarge {
    align-items: baseline;
  }
  .row.reverse_xlarge {
    flex-direction: row-reverse;
  }
  .row.reverse_xlarge .row {
    flex-direction: row;
  }
  .row.half_column_margin {
    margin-left: -12.5px;
    margin-right: -12.5px;
  }
  .row.half_column_margin > [class*=span] {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
}
@media screen and (min-width: 1200px) {
  .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    justify-content: space-between;
  }
  .row.start_xxlarge {
    justify-content: flex-start;
  }
  .row.center_xxlarge {
    justify-content: center;
  }
  .row.end_xxlarge {
    justify-content: flex-end;
  }
  .row.top_xxlarge {
    align-items: flex-start;
  }
  .row.middle_xxlarge {
    align-items: center;
  }
  .row.bottom_xxlarge {
    align-items: flex-end;
  }
  .row.baseline_xxlarge {
    align-items: baseline;
  }
  .row.reverse_xxlarge {
    flex-direction: row-reverse;
  }
  .row.reverse_xxlarge .row {
    flex-direction: row;
  }
  .row.half_column_margin {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row.half_column_margin > [class*=span] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 414px) {
  [class*="span"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 640px) {
  [class*="span"] {
    padding-left: 15px;
    padding-right: 15px;
  }
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  [class*="span"] {
    padding-left: 20px;
    padding-right: 20px;
  }
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  [class*="span"] {
    padding-left: 25px;
    padding-right: 25px;
  }
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  [class*="span"] {
    padding-left: 30px;
    padding-right: 30px;
  }
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 640px) {
  .input_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.8em;
  }
}
@media screen and (min-width: 1200px) {
  .input_wrapper {
    margin: 0 -1.6em;
  }
}
@media screen and (min-width: 640px) {
  .textarea_wrapper {
    padding: 0 0.8em;
    margin: 0 -0.8em;
  }
}
@media screen and (min-width: 640px) {
  .input_field {
    min-width: 50%;
    padding: 0 0.8em;
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .input_field {
    padding: 0 1.6em;
  }
}
@media screen and (min-width: 640px) {
  .button_wrapper {
    display: flex;
  }
}
@media screen and (min-width: 640px) {
  button {
    margin-left: auto;
    width: auto;
  }
}
@media screen and (min-width: 414px) {
  [role="dialog"] {
    padding: 1.6em;
  }
}
@media screen and (min-width: 1200px) {
  #baguetteBox-overlay .full-image {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_wrapper,
  .property_specific_selection_wrapper {
    margin: 3.2em auto 1.6em;
  }
}
@media screen and (min-width: 1200px) {
  .property_search,
  .property_specific_selection {
    margin-bottom: 0.8em;
  }
}
@media screen and (min-width: 1024px) {
  .property_search .title,
  .property_specific_selection .title {
    width: 60%;
  }
}
@media screen and (min-width: 1200px) {
  .property_search .text_wrapper,
  .property_specific_selection .text_wrapper {
    padding: 2.4em 2.4em 1.6em;
  }
}
@media screen and (min-width: 1024px) {
  .property_search_entry,
  .property_specific_selection_entry {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry,
  .property_specific_selection_entry {
    border: 0;
    margin: 1.6em auto;
  }
}
@media screen and (min-width: 640px) {
  .property_search_entry .description_wrapper,
  .property_specific_selection_entry .description_wrapper {
    padding: 1.6em 1.6em 0;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry .description_wrapper,
  .property_specific_selection_entry .description_wrapper {
    padding-top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .property_search_entry .description,
  .property_specific_selection_entry .description {
    min-width: 55%;
    width: 55%;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry .description,
  .property_specific_selection_entry .description {
    padding: 0.8em 2.4em 1.6em 1.6em;
  }
}
@media screen and (min-width: 1024px) {
  .property_search_entry .image,
  .property_specific_selection_entry .image {
    min-width: 45%;
    width: 45%;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry .image,
  .property_specific_selection_entry .image {
    padding: 1.6em 0 1.6em 1.6em;
    display: flex;
  }
}
@media screen and (min-width: 414px) {
  .property_search_entry .image .discreet,
  .property_specific_selection_entry .image .discreet {
    height: 300px;
  }
}
@media screen and (min-width: 640px) {
  .property_search_entry .image .discreet,
  .property_specific_selection_entry .image .discreet {
    height: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry .image .discreet,
  .property_specific_selection_entry .image .discreet {
    background-position: 50% 0;
    background-size: 120%;
    height: auto;
    flex-grow: 1;
  }
}
@media screen and (min-width: 414px) {
  .property_search_entry .image .discreet .icon,
  .property_specific_selection_entry .image .discreet .icon {
    margin: 2em auto 0;
  }
}
@media screen and (min-width: 640px) {
  .property_search_entry .image .discreet .icon,
  .property_specific_selection_entry .image .discreet .icon {
    height: 50%;
    margin: 0 auto;
    padding: 0;
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .property_search_entry .property_details_search,
  .property_specific_selection_entry .property_details_search {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.6em;
            column-gap: 1.6em;
  }
}
@media screen and (min-width: 414px) {
  .property_search_entry .more_details,
  .property_specific_selection_entry .more_details {
    margin: 0.8em 0 2.4em;
  }
}
@media screen and (min-width: 1024px) {
  .property_search_entry .more_details,
  .property_specific_selection_entry .more_details {
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 1.6em;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry .more_details,
  .property_specific_selection_entry .more_details {
    border: 0;
    padding: 0;
    margin: 1.6em 1.6em 0.8em;
  }
}
@media screen and (min-width: 414px) {
  .property_search_entry .more_details a,
  .property_specific_selection_entry .more_details a {
    width: auto;
  }
}
@media screen and (min-width: 1200px) {
  .property_search_entry .more_details a,
  .property_specific_selection_entry .more_details a {
    margin-right: 0.8em;
  }
}
@media screen and (min-width: 640px) {
  .property_contact_call_to_action {
    display: flex;
  }
}
@media screen and (min-width: 1200px) {
  .property_contact_call_to_action {
    background: #fff;
    margin: 3.2em auto;
  }
}
@media screen and (min-width: 640px) {
  .property_contact_call_to_action .contact_starts {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    padding: 2.4em 1.6em;
    text-align: left;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .property_contact_call_to_action .contact_starts {
    min-width: 60%;
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .property_contact_call_to_action .contact_starts {
    min-width: 70%;
    padding: 2.4em 3.2em;
    width: 70%;
  }
}
@media screen and (min-width: 640px) {
  .property_contact_call_to_action .contact_ends {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .property_contact_call_to_action .contact_ends {
    min-width: 40%;
    width: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .property_contact_call_to_action .contact_ends {
    min-width: 30%;
    width: 30%;
    padding: 2.4em;
  }
}
@media screen and (min-width: 414px) {
  .property_details .discreet {
    height: 230px;
  }
}
@media screen and (min-width: 640px) {
  .property_details .discreet {
    height: 270px;
  }
}
@media screen and (min-width: 768px) {
  .property_details .discreet {
    height: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .discreet {
    background-position: 50% 0;
    background-size: 120%;
    height: 420px;
    flex-grow: 1;
  }
}
@media screen and (min-width: 414px) {
  .property_details .discreet .icon {
    margin: 2em auto 0;
  }
}
@media screen and (min-width: 640px) {
  .property_details .discreet .icon {
    height: 50%;
    margin: 0 auto;
    padding: 0;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .introduction {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .introduction_starts {
    flex-basis: 55%;
    width: 30%;
  }
}
@media screen and (min-width: 1024px) {
  .property_details .introduction_wrapper .introduction_starts {
    flex-basis: 60%;
    padding-left: 2.4em;
    padding-right: 3.2em;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .introduction_wrapper .introduction_starts {
    padding-left: 4.8em;
    padding-right: 4.8em;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .details {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
  }
}
@media screen and (min-width: 1024px) {
  .property_details .introduction_wrapper .details {
    flex-basis: 40%;
    min-width: 40%;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .introduction_wrapper .details .text {
    padding: 1.6em 3.2em;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .introduction_wrapper .details .text .title {
    font-size: 122.22222222%;
  }
}
@media screen and (min-width: 640px) {
  .property_details .introduction_wrapper .details .text ul {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.6em;
            column-gap: 1.6em;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .details .text ul {
    -webkit-column-count: auto;
            column-count: auto;
  }
}
@media screen and (min-width: 640px) {
  .property_details .introduction_wrapper .details .links .print_expose,
  .property_details .introduction_wrapper .details .links .print_memo {
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .details .links .print_expose,
  .property_details .introduction_wrapper .details .links .print_memo {
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .introduction_wrapper .details .links .print_expose,
  .property_details .introduction_wrapper .details .links .print_memo {
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 640px) {
  .property_details .introduction_wrapper .details .links .recommend {
    border-right: 1px solid #fff;
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .details .links .recommend {
    border-right: 0;
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .introduction_wrapper .details .links .recommend {
    border-right: 1px solid #fff;
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 640px) {
  .property_details .introduction_wrapper .details .links .mark_object,
  .property_details .introduction_wrapper .details .links .print_memo {
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .details .links .mark_object,
  .property_details .introduction_wrapper .details .links .print_memo {
    min-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .property_details .introduction_wrapper .details .links .mark_object,
  .property_details .introduction_wrapper .details .links .print_memo {
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .property_number {
    top: -50px;
  }
}
@media screen and (min-width: 1024px) {
  .property_details .introduction_wrapper .property_number {
    width: 60%;
  }
}
@media screen and (min-width: 768px) {
  .property_details .introduction_wrapper .property_number .text {
    margin-left: auto;
  }
}
@media screen and (min-width: 640px) {
  .new_property {
    bottom: -60px;
    height: 120px;
    padding: 10px 0;
    right: -60px;
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .new_property {
    bottom: -100px;
    font-size: 187.5%;
    height: 200px;
    padding: 20px 0;
    right: -100px;
    width: 200px;
  }
}
@media screen and (min-width: 640px) {
  .successfully_mediated_wrapper {
    top: 50%;
    margin-top: -28px;
  }
}
@media screen and (min-width: 640px) {
  .successfully_mediated {
    width: auto;
  }
}
@media screen and (min-width: 640px) {
  .discreet_sale {
    margin-top: 0.8em;
    width: 250px;
  }
}
@media screen and (min-width: 768px) {
  .property_details_slider .control li {
    height: 50px;
    margin-top: -25px;
    padding: 12px 15px 10px;
    width: 50px;
  }
}
@media screen and (min-width: 768px) {
  .property_details_slider .control li.prev {
    left: 2.4em;
  }
}
@media screen and (min-width: 768px) {
  .property_details_slider .control li.next {
    right: 2.4em;
  }
}
@media screen and (min-width: 768px) {
  .property_details_long_description {
    padding-bottom: 1.6em;
    padding-top: 1.6em;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_long_description {
    padding: 2.4em;
  }
}
@media screen and (min-width: 1200px) {
  .property_details_long_description {
    padding: 3.2em 4.8em;
  }
}
@media screen and (min-width: 768px) {
  .property_details_long_description:before {
    content: '';
    border-top: 1px solid #c2c2c2;
    display: block;
    margin-bottom: 0.8em;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .property_details_long_description .title {
    margin-bottom: 1.6em;
    text-align: center;
  }
  .property_details_long_description .title:after {
    content: '';
    left: 50%;
    right: 50%;
    margin-left: -1.6em;
  }
}
@media screen and (min-width: 768px) {
  .property_details_long_description .description_text {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.6em;
            column-gap: 1.6em;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_long_description .description_text {
    -webkit-column-gap: 2.4em;
            column-gap: 2.4em;
  }
}
@media screen and (min-width: 1200px) {
  .property_details_long_description .description_text {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media screen and (min-width: 768px) {
  .property_details_images_no_image_enlargement .image_container,
  .property_details_images .image_container {
    height: 160px;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_images_no_image_enlargement .image_container,
  .property_details_images .image_container {
    height: 190px;
    max-width: 25%;
    min-width: 25%;
    width: 25%;
  }
}
@media screen and (min-width: 1200px) {
  .property_details_images_no_image_enlargement .image_container,
  .property_details_images .image_container {
    height: 210px;
    max-width: 20%;
    min-width: 20%;
    width: 20%;
  }
}
@media screen and (min-width: 768px) {
  .property_details_images_no_image_enlargement .image_container .entry .description_text,
  .property_details_images .image_container .entry .description_text {
    bottom: -80px;
    height: 80px;
    padding: 2rem 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_images_no_image_enlargement .image_container .entry .description_text,
  .property_details_images .image_container .entry .description_text {
    bottom: -95px;
    height: 95px;
  }
}
@media screen and (min-width: 1200px) {
  .property_details_images_no_image_enlargement .image_container .entry .description_text,
  .property_details_images .image_container .entry .description_text {
    bottom: -105px;
    height: 105px;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_contact {
    min-height: 800px;
    padding: 1.6em 0 1.6em;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_contact .image {
    background-repeat: no-repeat;
    background-position: 25% 30px;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .property_details_contact .text_wrapper {
    margin: 0 0 0 auto;
    padding: 0 2.4em;
    width: 65%;
  }
}
@media screen and (min-width: 1200px) {
  .property_details_contact .text_wrapper {
    padding: 0 6.4em 0 3.2em;
  }
}
@media screen and (min-width: 1200px) {
  .social_media_links {
    left: 0;
    right: inherit;
    top: 180px;
  }
}
@media screen and (min-width: 1200px) {
  .social_media_links a {
    border: 0;
  }
}
@media screen and (min-width: 640px) {
  .social_media_links a .icon {
    height: 50px;
    padding: 0.53333333em;
    width: 50px;
  }
}
@media screen and (min-width: 414px) {
  .social_media_share li {
    flex-basis: 200px;
    margin-right: 0.8em;
  }
}
@media screen and (min-width: 1200px) {
  .dropdown_navigation {
    align-items: center;
    display: flex;
    width: 100%;
  }
  .dropdown_navigation a {
    color: #fff;
  }
  .dropdown_navigation [role="menubar"] {
    display: flex;
    padding: 0;
  }
  .dropdown_navigation [role="menubar"] > li {
    align-items: center;
    display: flex;
    height: 105px;
  }
  .dropdown_navigation [role="menubar"] > li:hover .root_menuitem,
  .dropdown_navigation [role="menubar"] > li.hover .root_menuitem {
    background: rgba(61, 2, 7, 0.25);
  }
  .dropdown_navigation [role="menubar"] > li:hover .first_menu,
  .dropdown_navigation [role="menubar"] > li.hover .first_menu {
    display: flex;
  }
  .dropdown_navigation .root_menuitem {
    align-items: center;
    display: flex;
    font-weight: 300;
    line-height: 65px;
    padding: 0 0.8em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .dropdown_navigation .root_menuitem:focus {
    outline: 1px solid #a9a9a9;
  }
  .dropdown_navigation .root_menuitem.active {
    background: rgba(61, 2, 7, 0.25);
  }
  .dropdown_navigation .root_menuitem:hover:focus,
  .dropdown_navigation .root_menuitem.hover:focus {
    outline: 0;
  }
  .dropdown_navigation .root_menuitem:hover .icon,
  .dropdown_navigation .root_menuitem.hover .icon {
    fill: rgba(255, 255, 255, 0.5);
  }
  .dropdown_navigation .root_menuitem .icon {
    display: block;
    fill: #fff;
    height: 15px;
    line-height: 10px;
    margin-left: 10px;
    width: 10px;
  }
  .dropdown_navigation .first_menu {
    background: rgba(255, 255, 255, 0.88);
    display: none;
    left: 1.6em;
    position: absolute;
    right: 1.6em;
    top: 105px;
  }
  .dropdown_navigation .first_menu li {
    display: flex;
    flex: 1 0 20%;
  }
  .dropdown_navigation .first_menu li:nth-child(1) .icon {
    width: 85px;
  }
  .dropdown_navigation .first_menu li:nth-child(2) .icon {
    width: 105px;
  }
  .dropdown_navigation .first_menu li:nth-child(3) .icon {
    width: 80px;
  }
  .dropdown_navigation .first_menu li:nth-child(4) .icon {
    width: 105px;
  }
  .dropdown_navigation .first_menu li:nth-child(5) .icon {
    width: 80px;
  }
  .dropdown_navigation .first_menu a {
    display: block;
    flex-grow: 1;
    text-align: center;
    text-decoration: none;
    padding: 1.6em;
  }
  .dropdown_navigation .first_menu a .icon {
    display: block;
    fill: #16364e;
    height: 130px;
    margin: 0 auto;
  }
  .dropdown_navigation .first_menu a .title {
    color: #333;
    font-size: 1.8rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  .dropdown_navigation .first_menu a:focus {
    background: rgba(255, 255, 255, 0.06);
    outline: 1px solid #a3a3a3;
  }
  .dropdown_navigation .first_menu a.active,
  .dropdown_navigation .first_menu a:hover {
    background: rgba(255, 255, 255, 0.06);
  }
  .dropdown_navigation .first_menu a.active .icon,
  .dropdown_navigation .first_menu a:hover .icon {
    fill: #333;
  }
}
@media screen and (min-width: 1200px) {
  .show_mobile_menu {
    display: none;
  }
}
@media screen and (min-width: 640px) {
  .notification_center {
    bottom: 1.6em;
    right: 1.6em;
    width: 360px;
  }
}
@media screen and (min-width: 640px) {
  .notification_center .entries {
    max-height: 206px;
  }
}
@media screen and (min-width: 640px) {
  .notification_center .entry .title_wrapper {
    max-width: 280px;
  }
}
@media screen and (min-width: 1200px) {
  .image_text {
    margin: 0 auto;
  }
}
@media screen and (min-width: 640px) {
  .teaser_overview {
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .teaser_overview {
    background: #fff;
    margin: 3.2em auto;
  }
}
@media screen and (min-width: 640px) {
  .teaser_overview .teaser {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 2.4em;
  }
}
@media screen and (min-width: 640px) {
  .teaser_overview .teaser .left {
    display: block;
  }
}
@media screen and (min-width: 640px) {
  .teaser_overview .teaser .right {
    flex-basis: 75%;
    padding-left: 1.6em;
    width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .leistungen_overview {
    display: flex;
    align-items: flex-start;
  }
  .leistungen_overview .award {
    padding-left: 1.92em;
  }
}
@media screen and (min-width: 1024px) {
  .leistungen_overview .award {
    min-width: 25%;
    max-width: 30%;
  }
}
@media screen and (min-width: 1024px) {
  .leistungen_overview .award img {
    float: none;
    margin: 0 auto;
  }
}
@media screen and (min-width: 768px) {
  .leistungen_overview .kundenstimmen .entry_wrapper {
    display: flex;
  }
  .leistungen_overview .kundenstimmen .entry_wrapper .entry {
    flex-basis: 50%;
  }
  .leistungen_overview .kundenstimmen .entry_wrapper .entry:nth-child(2n+1) {
    padding-right: 0.8em;
  }
  .leistungen_overview .kundenstimmen .entry_wrapper .entry:nth-child(2n) {
    padding-left: 0.8em;
  }
  .leistungen_overview .kundenstimmen .entry_wrapper .entry h3 {
    padding: 0;
  }
}
@media screen and (min-width: 414px) {
  .leistungen_overview .kundenstimmen .entry_wrapper h3 a {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 640px) {
  .leistungen_overview .kundenstimmen .entry_wrapper h3 a {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 768px) {
  .leistungen_overview .kundenstimmen .entry_wrapper h3 a {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 1024px) {
  .leistungen_overview .kundenstimmen .entry_wrapper h3 a {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 1200px) {
  .leistungen_overview .kundenstimmen .entry_wrapper h3 a {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .header_wrapper {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 1200px) {
  .company_logo {
    min-width: 289px;
    padding: 0 1.6em;
  }
}
@media screen and (min-width: 640px) {
  .mobile_menu_logo_wrapper {
    min-width: auto;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .error_page main {
    padding: 1.6em 6.4em;
  }
}
@media screen and (min-width: 1200px) {
  .fixed_background_wrapper {
    display: block;
    left: 0;
    position: fixed;
    top: 0;
    right: 0;
    min-height: 100vh;
  }
}
@media screen and (min-width: 768px) {
  .header_startpage {
    height: 580px;
  }
}
@media screen and (min-width: 640px) {
  .header_startpage h1 {
    font-size: 213.33333333%;
  }
}
@media screen and (min-width: 1024px) {
  .header_startpage h1 {
    font-size: 280%;
  }
}
@media screen and (min-width: 1200px) {
  .header_startpage h1 {
    font-size: 343.75%;
  }
}
@media screen and (min-width: 768px) {
  .header_startpage h1:after {
    margin-left: -180px;
    width: 360px;
  }
}
@media screen and (min-width: 768px) {
  .header_startpage .header_photo {
    height: 640px;
  }
}
@media screen and (min-width: 640px) {
  .description_karin_gornik .photo {
    padding: 2.08em 0 1.6em 1.6em;
  }
}
@media screen and (min-width: 768px) {
  .description_karin_gornik .photo {
    padding: 2.08em 0 1.6em 2.4em;
  }
}
@media screen and (min-width: 1024px) {
  .description_karin_gornik .photo {
    padding: 3.2em 0 1.6em 3.2em;
  }
}
@media screen and (min-width: 640px) {
  .description_karin_gornik .text_wrapper {
    padding: 0.8em 1.6em 1.6em 0;
  }
}
@media screen and (min-width: 768px) {
  .description_karin_gornik .text_wrapper {
    padding: 0.8em 3.2em 1.6em 0;
  }
}
@media screen and (min-width: 1024px) {
  .description_karin_gornik .text_wrapper {
    padding: 1.6em 4.8em 2em 0;
  }
}
@media screen and (min-width: 1200px) {
  .description_karin_gornik .text_wrapper {
    padding: 1.6em 6.4em 2em 0;
  }
}
@media screen and (min-width: 640px) {
  .about_gornik {
    display: flex;
  }
}
@media screen and (min-width: 1200px) {
  .about_gornik {
    background: #fff;
    margin: 3.2em auto;
  }
}
@media screen and (min-width: 640px) {
  .about_gornik .contact_starts {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    padding: 2.4em 1.6em;
    text-align: left;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .about_gornik .contact_starts {
    min-width: 60%;
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .about_gornik .contact_starts {
    min-width: 70%;
    padding: 2.4em 3.2em;
    width: 70%;
  }
}
@media screen and (min-width: 640px) {
  .about_gornik .contact_ends {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .about_gornik .contact_ends {
    min-width: 40%;
    width: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .about_gornik .contact_ends {
    min-width: 30%;
    width: 30%;
    padding: 2.4em;
  }
}
@media screen and (min-width: 768px) {
  .activate_search_agent {
    display: flex;
    justify-content: center;
    padding: 1.6em 0;
  }
}
@media screen and (min-width: 768px) {
  .activate_search_agent .title {
    margin: 0;
    max-width: 350px;
    padding: 0 0.8em;
    width: 350px;
  }
}
@media screen and (min-width: 1024px) {
  .activate_search_agent .title {
    max-width: 450px;
    padding: 0 0.2em;
    width: 450px;
  }
}
@media screen and (min-width: 768px) {
  .activate_search_agent .icon {
    display: block;
    fill: rgba(255, 255, 255, 0.2);
    height: 80px;
    margin: 0 1.6em;
    opacity: 0.5;
    width: 80px;
  }
}
@media screen and (min-width: 768px) {
  .activate_search_agent .text_wrapper {
    max-width: 350px;
    padding: 0 1.6em;
    text-align: left;
    width: 350px;
  }
}
@media screen and (min-width: 1024px) {
  .activate_search_agent .text_wrapper {
    max-width: 450px;
    padding: 0 2.4em;
    width: 450px;
  }
}
@media screen and (min-width: 640px) {
  .dialog_search_agent .input_field {
    width: 80%;
  }
}
@media screen and (min-width: 1200px) {
  .search_agent_content {
    margin: 3.2em auto 0;
    padding: 1.6em 3.2em;
  }
}
@media screen and (min-width: 1200px) {
  .legal_notice_address,
  .address_atikon,
  .legal_notice_text,
  .default_text {
    margin: 3.2em auto 0;
    padding: 1.6em 3.2em;
  }
}
@media screen and (min-width: 1200px) {
  .legal_notice_text {
    margin: 0 auto;
    padding: 0 3.2em 1px;
  }
}
@media screen and (min-width: 1200px) {
  .address_atikon {
    margin: 0 auto 3.2em;
    padding: 0 3.2em 1.6em;
  }
}
@media screen and (min-width: 768px) {
  .footer_address {
    display: flex;
    padding: 3.2em 0;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address {
    padding: 3.2em 0 4.8em;
  }
}
@media screen and (min-width: 768px) {
  .footer_address .logos {
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address .logos {
    padding: 0 0.8em;
  }
}
@media screen and (min-width: 768px) {
  .footer_address .address {
    min-width: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address .address {
    padding: 0 1.6em;
  }
}
@media screen and (min-width: 768px) {
  .footer_address .address {
    border-bottom: 0;
    border-right: 1px solid #a9a9a9;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address .logos {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .footer_address .logos img {
    margin: 0.8em 0;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address .logos .logo_wrapper_1 {
    flex-direction: row;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px) {
  .footer_address .logos .logo_wrapper_4 img {
    margin: 0;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address .logos .logo_wrapper_3 img {
    margin: 0 0 0.8em;
  }
}
@media screen and (min-width: 1200px) {
  .footer_address .logos .logo_wrapper_3 {
    padding-left: 1.6em;
  }
}
@media screen and (min-width: 1200px) {
  .news_overview .image_text .header {
    margin: 3.2em 0 1.6em 0;
  }
}
@media screen and (min-width: 1200px) {
  .news_overview .entry {
    border: 0;
    margin: 0 0 1.6em 0;
  }
}
@media screen and (min-width: 640px) {
  .news_overview .text {
    margin-top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .news_overview .text .button_wrapper {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 640px) {
  .news_entry .property_details_images_no_image_enlargement .image_container,
  .news_entry .property_details_images .image_container {
    height: 100px;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .news_entry .property_details_images_no_image_enlargement .image_container,
  .news_entry .property_details_images .image_container {
    height: 80px;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 1024px) {
  .news_entry .property_details_images_no_image_enlargement .image_container,
  .news_entry .property_details_images .image_container {
    height: 100px;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 1200px) {
  .news_entry .property_details_images_no_image_enlargement .image_container,
  .news_entry .property_details_images .image_container {
    height: 120px;
    max-width: 33.33333333%;
    min-width: 33.33333333%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 414px) {
  #bellevue_logo_wrapper {
    width: 70px;
  }
}
@media screen and (min-width: 640px) {
  #bellevue_logo_wrapper {
    width: 90px;
  }
}
@media screen and (max-width: 1200px) {
  #property_details_images .video_container {
    height: 188px !important;
  }
}
@media screen and (max-width: 1024px) {
  #property_details_images .video_container {
    height: 160px !important;
  }
}
@media screen and (max-width: 768px) {
  #property_details_images .video_container {
    height: 140px !important;
  }
}
